<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5" style="z-index: 2;">
            <template v-slot:conteudo>
              <p>
                O distrito de Monsenhor Horta consistiu em um dos mais
                importantes arraiais da rota do ouro margeando o Ribeirão do
                Carmo. Sua ocupação é longitudinal, acompanhando a sinuosidade
                do rio. Parte do casario foi implantado nessa extensão de
                planície e parte nas áreas de relevo mais íngreme.
              </p>
              <p>
                Em local de destaque, em ponto alto do povoado, foi erguida a
                Matriz de São Caetano por iniciativa da Irmandade do Santíssimo
                Sacramento. O traçado das vias acompanha as curvas de nível
                predominantemente, havendo no entorno da Igreja amplo gramado
                que configura o adro à sua frente.
              </p>
              <!-- <p>
                O distrito de Monsenhor Horta pertence ao município de
                Mariana/MG e possui como subdistritos
                <router-link tag="a" style="text-decoration: none" class="pink--text" :to="{ name: 'sobre_paracatu_de_cima' }">Paracatu de Cima</router-link>,
                <router-link tag="a" style="text-decoration: none" class="pink--text" :to="{ name: 'sobre_paracatu_de_baixo' }">Paracatu de Baixo</router-link> e
                <router-link tag="a" style="text-decoration: none" class="pink--text" :to="{ name: 'sobre_ponte_do_gama' }">Ponte do Gama</router-link>. Situa-se a cerca
                de 16km do centro de Mariana e a 129 km de Belo Horizonte,
                capital do Estado de Minas Gerais.
              </p>
              <p>
                A formação do povoamento remete ao final do século XVII, quando
                o bandeirante coronel Salvador Fernandes Furtado de Mendonça
                saiu da região paulista de Taubaté e atravessou o interior de
                Minas. Ficou conhecido pela descoberta do ouro nos arredores de
                Mariana, incluindo o local onde surgiu o povoado de São Caetano
                (atual distrito de Monsenhor Horta). Ele era um devoto fervoroso
                e tinha permissão do bispo do Rio de Janeiro para levar consigo
                um altar portátil, bem como para edificar capelas.
              </p> -->
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_monsenhor_01.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Vista da paisagem na região de Monsenhor Horta. Foto: Plínio Lins,
            2016.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-end align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_monsenhor_02.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Vista da fachada frontal e lateral esquerda da Igreja Matriz de São
            Caetano, em Monsenhor Horta. Foto: Flora Passos, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5" style="z-index: 2;">
            <template v-slot:conteudo>
              <p>
                O conjunto arquitetônico é composto por casarões, em parte
                remanescentes do período colonial em meio a edificações
                contemporâneas. Seguiu a conformação urbanística dos núcleos do
                período colonial, com as casas implantadas com a fachada frontal
                sobre o alinhamento da via, que eram então definidas pelo
                casario. O sistema construtivo caracteriza-se pela técnicas
                tradicionais, com fundações de pedra, estrutura independente de
                madeira (gaiolas) e fechamento com paredes de pau a pique (trama
                com varas de taquara e barro), rebocadas com argamassa de areia
                e cal e pintura a base de cal. O gabarito das edificações é de
                um e dois pavimentos, de modo que a Igreja Matriz continua
                exercendo sua condição de referência e destaque na paisagem.
              </p>
              <p>
                O Núcleo Histórico do Distrito Monsenhor Horta foi protegido por
                tombamento por parte do município de Mariana devido à sua
                importância cultural para a cidade, por meio do Decreto n°
                5630/2010.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5" style="z-index: 2;">
            <template v-slot:conteudo>
              <p>
                Da mesma forma que os demais distritos e subdistritos de
                Mariana, as casas possuem quintais com espaços de horta e
                diversidade de árvores frutíferas, tendo à disposição dos
                moradores, produtos frescos, livres de agrotóxicos e
                fertilizantes químicos.
              </p>
              <p>
                As cozinhas, que em grande parte ainda mantém seus
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{
                    name: 'conteudo_soberania_beira_fogao',
                  }"
                  >fogões a lenha</router-link
                >, são locais de conversas entre as pessoas da família e onde
                são recebidos os visitantes.
              </p>
              <p style="text-align: center">
                <strong> Autora: Ana Paula Alves Ferreira<sup>1</sup> </strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_monsenhor_03.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Vista de uma rua de Monsenhor Horta, com destaque para as casas no
            estilo colonial, e a Igreja Matriz aos fundos. Foto: Plínio Lins,
            2016.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="table__section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
.green__stripe {
  position: relative;
}

.green__stripe:before {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  height: 110px;
  width: 200%;
  background-color: #04441f;
}

h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}

.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
